<template>
  <div class="registerPageWrapper">
    <div>
      <div class="registerPageHeader">
        <h1>Get started for free</h1>
        <p>
          <span class="pageIntroText"
            >Keep score. Enjoy your stats. Build and host events. Do it all with
            Tjing.</span
          >
          <span class="loginSection">
            Already using Tjing?
            <router-link
              :to="{ name: 'login', params: { source: 'home' } }"
              class="internalLink"
              >Log in</router-link
            >
          </span>
        </p>
      </div>
    </div>

    <div>
      <div class="inputSection">
        <p class="input-label-text">First name</p>
        <input
          @keyup.enter="signup()"
          v-model="firstName.value"
          name="fname"
          type="text"
        />
      </div>
      <div class="inputSection">
        <p class="input-label-text">Last name</p>
        <input
          @keyup.enter="signup()"
          v-model="lastName.value"
          name="lname"
          type="text"
        />
      </div>
      <div class="inputSection">
        <p class="input-label-text">Email</p>
        <input
          @keyup.enter="signup()"
          v-model="email.value"
          name="email"
          type="text"
        />
      </div>
      <div class="inputSection">
        <div class="input-label-text">
          Password
          <p
            class="showHidePasswordIndicator"
            v-if="showPassword"
            @click="showPassword = !showPassword"
          >
            <img src="@/assets/img/show-mid.svg" />
            <span>Hide</span>
          </p>
          <p
            class="showHidePasswordIndicator"
            v-else
            @click="showPassword = !showPassword"
          >
            <img src="@/assets/img/hide-mid.svg" />
            <span>Show</span>
          </p>
        </div>
        <input
          @keyup.enter="signup()"
          v-if="showPassword"
          v-model="password.value"
          name="password"
          type="text"
        />
        <input
          @keyup.enter="signup()"
          v-else
          v-model="password.value"
          name="password"
          type="password"
        />
        <p class="passwordStrengthIndicator">
          At least
          <span>7 characters</span> and
          <span>1 digit</span>
        </p>
      </div>
    </div>

    <div class="confirmSection">
      <div>
        <button @click="signup()" class="shampoo full tall">
          Get started!
        </button>
      </div>
      <div>
        <transition name="fade">
          <p class="error-message" v-if="errorMessage.length > 0">
            {{ errorMessage }}
          </p>
        </transition>
      </div>
      <div>
        <p>
          By clicking the
          <span>Get started</span> button you agree to Tjing's
          <router-link class="internalLink" to="/legal/terms"
            >Terms&nbsp;of&nbsp;Use</router-link
          >and
          <router-link class="internalLink" to="/legal/privacy"
            >Privacy Policy</router-link
          >.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import validator from "validator";

export default {
  name: "TheCreateAccountPage",
  data() {
    return {
      firstName: {
        value: "",
        valid: false,
      },
      lastName: {
        value: "",
        valid: false,
      },
      email: {
        value: "",
        valid: false,
      },
      password: {
        value: "",
        valid: false,
      },
      showPassword: false,
      errorMessage: "",
    };
  },
  watch: {
    firstName: {
      handler: function (oldValue, newValue) {
        if (newValue.value.length > 1) {
          this.firstName.valid = true;
          this.errorMessage = "";
        } else {
          this.firstName.valid = false;
        }
      },
      deep: true,
    },
    lastName: {
      handler: function (oldValue, newValue) {
        if (newValue.value.length > 1) {
          this.lastName.valid = true;
          this.errorMessage = "";
        } else {
          this.lastName.valid = false;
        }
      },
      deep: true,
    },
    email: {
      handler: function (oldValue, newValue) {
        if (validator.isEmail(newValue.value)) {
          this.email.valid = true;
          this.errorMessage = "";
        } else {
          this.email.valid = false;
        }
      },
      deep: true,
    },
  },
  computed: {},
  methods: {
    loginUser(email, password) {
      this.wrongLogin = false;

      this.$axios({
        data: {
          query: `
        mutation {
          CreateAuthToken(email:"${email}",password:"${password}"){
            userId
            token
            expireAt
          }
        }
        `,
        },
      }).then((result) => {
        if (result.data.data.CreateAuthToken == null) {
          this.wrongLogin = true;
        } else {
          var tokenObject = result.data.data.CreateAuthToken;
          tokenObject.token = `Bearer ${result.data.data.CreateAuthToken.token}`;

          this.$axios({
            headers: { Authorization: tokenObject.token },
            data: {
              query: `
              query {
                me
                {
                  id
                  firstName
                  lastName
                }
              }
            `,
            },
          }).then((result) => {
            tokenObject.firstName = result.data.data.me.firstName;
            tokenObject.lastName = result.data.data.me.lastName;

            this.$store.dispatch("setSessionInfo", tokenObject);

            localStorage.setItem("userObject", JSON.stringify(tokenObject));

            this.$store.dispatch("setUserInfo", result.data.data.me);
            this.$store.dispatch("checkForOpenGroups", true);
            this.$store.dispatch("authenticationState", true);

            this.$router.push({
              name: "home",
              params: this.$router.currentRoute.params.params,
            });
          });
        }
      });
    },
    signup() {
      this.errorMessage = "";

      if (!this.firstName.valid) {
        this.errorMessage = "Everyone needs a name. Even if you are no one.";
      } else if (!this.lastName.valid) {
        this.errorMessage =
          "We know it’s a nuisance but we need your last name to keep all the " +
          this.firstName.value +
          "’s apart.";
      } else if (!this.email.valid) {
        if (this.email.value.length == 0) {
          this.errorMessage =
            "Your email looks short, or to be honest: non existent.";
        } else {
          this.errorMessage = "Are you sure that’s a valid email? We’re not.";
        }
      } else {
        this.$axios({
          data: {
            query: `
              mutation CreateAccount($createAccountInput:CreateAccountInput!){
                CreateAccount(input:$createAccountInput){
                  id
                }
              }`,
            variables: {
              createAccountInput: {
                email: this.email.value,
                firstName: this.firstName.value,
                lastName: this.lastName.value,
                password: this.password.value,
              },
            },
          },
        })
          .then((result) => {
            if (result.data.data.CreateAccount == null) {
              if (result.data.errors[0].errorCode == "EMAIL_ALREADY_IN_USE") {
                this.errorMessage =
                  "It would seem that email is already in use, try log in instead?";
              } else {
                this.errorMessage =
                  "Your password isn’t up to par – it wants at least seven characters and one digit.";
              }
            } else {
              this.loginUser(this.email.value, this.password.value);
            }
          })
          .catch(() => {
            console.log("Failed");
          });
      }
    },
  },
  mounted() {},
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.registerPageWrapper {
  max-width: 90%;
  margin: 0 auto;
  padding-bottom: 60px;
}
.registerPageHeader {
  text-align: center;
}
h1 {
  font-size: 26px;
  @include Gilroy-Bold;
  margin-bottom: 10px !important;
}
p {
  margin: 0;
}
.pageIntroText {
  display: none;
}
.loginSection {
  display: flex;
  justify-content: center;
}
input {
  background: white;
  border-radius: 6px;
  border: 1px solid $mouse;
  height: 50px;
  font-size: 16px;
  @include Gilroy-Bold;
  outline: none;
  padding-left: 15px;
  width: 100%;
  color: #7d8296;
}
button {
  width: 60%;
  height: 40px;
}

a {
  color: $sky;
  @include Gilroy-Medium;
}
.inputSection {
  margin-top: 10px;
}
.input-label-text {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0px;
  @include Gilroy-Bold;
  .showHidePasswordIndicator {
    display: flex;
    align-items: center;
    cursor: pointer;
    span {
      width: 45px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @include Gilroy-Bold;
      color: #7d8296;
    }
    img {
      width: 24px;
      height: 24px;
    }
  }
}
.passwordStrengthIndicator {
  font-size: 12px;
  margin-top: 6px;
  color: #7d8296;
  span {
    @include Gilroy-Bold;
  }
}
.link {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 40%;
}
.confirmSection {
  margin-top: 43px;
  text-align: center;
  p {
    font-size: 12px !important;
    margin-top: 12px;
    span {
      @include Gilroy-Bold;
    }
    .internalLink {
      display: inline-block;
      margin-left: 0;
      margin-right: 2px;
    }
  }
  .error-message {
    color: $dusk;
    font-size: 16px !important;
    line-height: 20px;
    @include Gilroy-Bold;
    max-width: 92%;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
  .registerPageWrapper {
    max-width: 512px;
    h1 {
      margin-top: 0px;
    }
  }
  .pageIntroText {
    display: block;
  }
  h1 {
    margin-bottom: 30px;
    font-size: 32px;
  }
  .passwordStrengthIndicator {
    font-size: 14px;
  }
  .inputSection {
    margin-top: 14px;
  }
  .confirmSection {
    margin-top: 22px;
  }
}

@media (min-width: 992px) {
}
</style>
